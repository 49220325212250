define("boondmanager/models/bankingtransactionpayment", ["exports", "ember-data", "ember-data-model-fragments", "ember-data-model-fragments/attributes"], function (_exports, _emberData, _emberDataModelFragments, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('moment'),
    amountIncludingTax: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    comment: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    invoice: (0, _attributes.fragment)('invoicefragment'),
    owner: _emberDataModelFragments.default.fragmentOwner()
  });
});