define("boondmanager/controllers/search", ["exports", "boondmanager/controllers/base", "ember-copy", "boondmanager/utils/logger", "boondmanager/utils/exports", "jquery", "boondmanager/services/remote-logger"], function (_exports, _base, _emberCopy, _logger, _exports2, _jquery, _remoteLogger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Contrôleur parent des modules de recherches
   *
   * @class  SearchController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = _base.default.extend({
    /** SERVICES */
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    remoteLogger: Ember.inject.service(),
    modalGlobalMessage: Ember.inject.service(),
    /** PROPERTIES */

    /**
     * Format pour l'extraction
     *
     * @property extractFormat
     * @type {String}
     * @default 'csv'
     */
    extractFormat: 'csv',
    /**
     * Option d'encodage pour l'extraction
     *
     * @property extractOption
     * @type {String}
     * @default 'ISO-8859-15'
     */
    extractOption: 'ISO-8859-15',
    /**
     * Affichage de la modale de confirmation de l'extraction
     *
     * @property showConfirmExtract
     * @type {boolean}
     * @default false
     */
    showConfirmExtract: false,
    /**
     * Affichage de la modale de confirmation pour l'export
     *
     * @property showConfirmExport
     * @type {boolean}
     * @default false
     */
    showConfirmExport: false,
    /**
     * Appliquer les filtres de recherche et la sélection pour l'extraction ou pas
     *
     * @property filteredExtract
     * @type {boolean}
     * @default true
     */
    filteredExtract: true,
    /**
     * Appliquer les filtres de recherche et la sélection pour l'export ou pas
     *
     * @property filteredExport
     * @type {boolean}
     * @default true
     */
    filteredExport: true,
    /**
     * Affichage de la modale de configuration des colonnes affichées
     *
     * @property showSettingsColumns
     * @type {boolean}
     * @default false
     */
    showSettingsColumns: false,
    /** COMPUTED */
    assignment: Ember.computed('assignments', 'rightsModule', 'rights.globalLastUpdate', function () {
      return this.get('assignments').getAssignment(this.get('rightsModule'));
    }).readOnly(),
    massMainManagers: Ember.computed('assignment.isFulfilled', function () {
      if (this.get('assignment.isFulfilled')) {
        return this.get('assignment.mainManagers');
      }
    }).readOnly(),
    massHrManagers: Ember.computed('assignment.isFulfilled', function () {
      if (this.get('assignment.isFulfilled')) {
        return this.get('assignment.hrManagers');
      }
    }).readOnly(),
    massInfluencers: Ember.computed('assignment.isFulfilled', function () {
      if (this.get('assignment.isFulfilled')) {
        return this.get('assignment.influencers');
      }
    }).readOnly(),
    massPoles: Ember.computed('assignment.isFulfilled', function () {
      if (this.get('assignment.isFulfilled')) {
        return this.get('assignment.poles');
      }
    }).readOnly(),
    massExpectedValidators: Ember.computed('assignment.isFulfilled', function () {
      if (this.get('assignment.isFulfilled')) {
        return this.get('assignment.expectedValidators');
      }
    }).readOnly(),
    canAssignFlag: Ember.computed('massFlags.[]', function () {
      return this.get('massFlags.length');
    }).readOnly(),
    canAssignMainManager: Ember.computed('advancedRights', 'assignment.isFulfilled', function () {
      if (!this.get('massMainManagers')) return false;
      var advancedRights = this.get('advancedRights.entity.fields');
      var field = advancedRights && advancedRights.find(function (field) {
        return field.name === 'mainManager';
      });
      return field && field.writeAccess.always;
    }),
    canAssignHrManager: Ember.computed('advancedRights', 'assignment.isFulfilled', function () {
      if (!this.get('massHrManagers')) return false;
      var advancedRights = this.get('advancedRights.entity.fields');
      var field = advancedRights && advancedRights.find(function (field) {
        return field.name === 'hrManager';
      });
      return field && field.writeAccess.always;
    }),
    canAssignInfluencers: Ember.computed('advancedRights', 'assignment.isFulfilled', function () {
      if (!this.get('massInfluencers')) return false;
      var advancedRights = this.get('advancedRights.entity.fields');
      var field = advancedRights && advancedRights.find(function (field) {
        return field.name === 'influencers';
      });
      return field && field.writeAccess.always;
    }),
    canAssignPole: Ember.computed('advancedRights', 'assignment.isFulfilled', function () {
      if (!this.get('massPoles')) return false;
      var advancedRights = this.get('advancedRights.entity.fields');
      var field = advancedRights && advancedRights.find(function (field) {
        return field.name === 'pole';
      });
      return field && field.writeAccess.always;
    }),
    canAssignExpectedValidator: Ember.computed('advancedRights', 'assignment.isFulfilled', function () {
      if (!this.get('massExpectedValidators')) return false;
      var advancedRights = this.get('advancedRights.entity.fields');
      var field = advancedRights && advancedRights.find(function (field) {
        return field.name === 'expectedValidator';
      });
      return field && field.writeAccess.always;
    }),
    /**
     * Récupère les différentes traduction
     * des paramétrages client
     **/
    flattenMobilityAreas: Ember.computed('i18n._locale', function () {
      var dict = this.get('settings').customer.mobilityArea;
      var flattenDict = {};
      for (var i = 0; i < dict.length; i++) {
        for (var j = 0; j < dict[i].option.length; j++) {
          flattenDict[dict[i].option[j].id] = dict[i].option[j].value;
        }
      }
      return flattenDict;
    }).readOnly(),
    /**
     * Récupère les différentes traduction
     * des paramétrages client
     **/
    flattenActivityAreasById: Ember.computed('i18n._locale', function () {
      var dict = this.get('settings').customer.activityArea;
      var flattenDict = {};
      for (var i = 0; i < dict.length; i++) {
        for (var j = 0; j < dict[i].option.length; j++) {
          flattenDict[dict[i].option[j].id] = dict[i].option[j].value;
        }
      }
      return flattenDict;
    }).readOnly(),
    /**
     * Récupère les différentes traduction
     * des paramétrages client
     **/
    flattenExpertiseAreas: Ember.computed('i18n._locale', function () {
      var dict = this.get('settings').customer.expertiseArea;
      var flattenDict = {};
      for (var i = 0; i < dict.length; i++) {
        flattenDict[dict[i].id] = dict[i].value;
      }
      return flattenDict;
    }).readOnly(),
    /**
     * Récupère les différentes traduction
     * des paramétrages client
     **/
    flattenActivityType: Ember.computed('i18n._locale', function () {
      var dict = this.get('settings').bm.common.activityType.options;
      var flattenDict = {};
      for (var i = 0; i < dict.length; i++) {
        flattenDict[dict[i].id] = dict[i].value;
      }
      return flattenDict;
    }).readOnly(),
    languagesList: Ember.computed('i18n._locale', function () {
      var languages = this.get('settings').customer.languageSpoken.filterBy('isEnabled');
      var levels = this.get('settings').customer.languageLevel.filterBy('isEnabled');
      return languages.map(function (language) {
        return {
          id: language.id,
          value: language.value,
          option: levels.map(function (level) {
            return {
              id: language.id + '|' + level.id,
              value: level.value
            };
          })
        };
      });
    }),
    toolsList: Ember.computed('i18n._locale', function () {
      var tools = this.get('settings').customer.tool.filterBy('isEnabled');
      var levels = [5, 4, 3, 2, 1];
      return tools.map(function (tool) {
        return {
          id: tool.id,
          value: tool.value,
          option: levels.map(function (level) {
            return {
              id: tool.id + '|' + level,
              value: level
            };
          })
        };
      });
    }).readOnly(),
    /**
     * Récupère les différentes traduction
     * des paramétrages client
     **/
    flattenActivityAreas: Ember.computed('i18n._locale', function () {
      var dict = this.get('settings').customer.activityArea;
      var flattenDict = {};
      for (var i = 0; i < dict.length; i++) {
        for (var j = 0; j < dict[i].option.length; j++) {
          flattenDict[dict[i].option[j].id] = dict[i].option[j].value;
        }
      }
      return flattenDict;
    }).readOnly(),
    /**
     * Pour afficher les étoiles
     **/
    toolLevels: Ember.computed(function () {
      return [0, 1, 2, 3, 4];
    }),
    /** METHODS */

    queryParamsForExtract: function queryParamsForExtract(selected, values) {
      var mainModelClass = this.get('mainModelClass');
      var queryParamsVar = this.get('queryParams');
      var queryParams = this.getProperties(queryParamsVar);
      queryParamsVar.forEach(function (queryParam) {
        // on prend en priorité les queryParams définis dans la modale d'extraction,
        // sinon les valeurs des queryParams du contrôleur (pour récupérer les valeurs de filtre ou valeurs par défaut)
        if (typeof values[queryParam] !== 'undefined') {
          queryParams[queryParam] = values[queryParam];
        }
      });
      if (selected.length > 0 && this.get('filteredExtract')) {
        queryParams.keywords = selected.map(function (item /*, index, enumerable*/) {
          return mainModelClass.prefix + item;
        }).join(' ');
      }
      return queryParams;
    },
    callUrlForExtract: function callUrlForExtract(queryParams) {
      var mainModel = this.get('mainModel');
      if (typeof this.get('extractApp') !== 'undefined') {
        mainModel = this.get('extractApp').mainModel;
      }
      var adapter = this.store.adapterFor(mainModel);

      // caution: by default, buildURL do not add GET queryParams in url like ?truc&toto...
      // BUT we use UrlTemplate and it manage queryParams :)
      window.location.href = adapter.buildURL(this.get('mainModel'), null, null, 'query', queryParams);
    },
    init: function init() {
      this.set('showConfirmExtract', false);
      this.set('showConfirmExport', false);
      this._super.apply(this, arguments);
    },
    /** ACTIONS **/
    actions: {
      /**
       * Action - Confirm extract all
       *
       * @event confirm extract all
       */
      confirmExtractAll: function confirmExtractAll() {
        this.set('filteredExtract', false);
        this.set('showConfirmExtract', true);
      },
      /**
       * Action - Confirm extract filtered
       *
       * @event confirm extract filtered
       */
      confirmExtract: function confirmExtract() {
        this.set('filteredExtract', true);
        this.set('showConfirmExtract', true);
      },
      /**
       * Action - Close extract modal
       *
       * @event close extract modal
       */
      closeConfirmExtractModal: function closeConfirmExtractModal() {
        this.set('showConfirmExtract', false);
      },
      /**
       * Action - Confirm export all
       *
       * @event confirm export all
       */
      confirmExportAll: function confirmExportAll() {
        this.set('filteredExport', false);
        this.set('showConfirmExport', true);
      },
      /**
       * Action - Confirm export filtered
       *
       * @event confirm export filtered
       */
      confirmExport: function confirmExport() {
        this.set('filteredExport', true);
        this.set('showConfirmExport', true);
      },
      /**
       * Action - updateFlag
       *
       * @event updateFlag
       * @param {string} flagID
       * @param {string} actionType "add" or "remove"
       */
      updateFlag: function updateFlag(flagID, actionType) {
        var _this = this;
        if (actionType !== 'add' && actionType !== 'remove') {
          _logger.default.warn("Bad actionType argument (".concat(actionType, ") when call updateFlag() action! Default to \"add\"."));
          actionType = 'add';
        }
        if (typeof flagID === 'undefined') {
          _logger.default.error("Missing \"flagID\" argument when call ".concat(actionType, "Flag() action!"));
          return;
        }
        if (Ember.typeOf(flagID) !== 'string' || Ember.isEmpty(flagID)) {
          _logger.default.error('Bad flagID argument when call ${actionType}Flag() action!');
          return;
        }

        // All is ok: we can begin :)
        var selected = this.get('selected');
        if (!selected) {
          return;
        }
        var mainModel = this.get('mainModel');
        var store = this.store;
        var i18n = this.get('i18n');
        var batchCount = selected.length;
        this.get('modalGlobalMessage').title = i18n.t('components:modals.batch.xItems.titlemodify');
        this.get('modalGlobalMessage').icons.push('bmi-edit-modal-icon');
        this.get('modalGlobalMessage').message = i18n.t("components:modals.batch.xItems.".concat(actionType, "flag"), {
          count: batchCount
        });
        this.get('modalGlobalMessage').buttons = [{
          type: 'cancel',
          label: i18n.t('common:actions.cancel'),
          className: 'bmb-rectangle bmb-rectangle-border-on-hover'
        }, {
          type: 'delete',
          label: i18n.t('common:actions.confirm'),
          className: 'bmb-rectangle bmb-confirm',
          callback: function callback() {
            var recordsToUpdate = [];

            // use copy() because we update "selected" content in the loop!
            (0, _emberCopy.copy)(selected).forEach(function (item) /*, index, enumerable*/{
              var type, data;
              switch (actionType) {
                case 'add':
                  type = 'POST';
                  data = {
                    data: {
                      type: 'attachedflag',
                      relationships: {
                        flag: {
                          data: {
                            id: flagID,
                            type: 'flag'
                          }
                        },
                        dependsOn: {
                          data: {
                            id: item,
                            type: mainModel
                          }
                        }
                      }
                    }
                  };
                  break;
                case 'remove':
                  type = 'DELETE';
                  data = {
                    flag: flagID
                  };
                  data[mainModel] = item;
                  break;
                default:
                  _logger.default.error("Bad actionType: ".concat(actionType, "!"));
                  return;
              }
              var record = store.peekRecord(mainModel, item);
              if (record) {
                var $row = (0, _jquery.default)("[data-row-id-".concat(item, "=true]"));
                $row.addClass('bm-danger');
                recordsToUpdate.push(new Ember.RSVP.Promise(function (resolve, reject) {
                  store.adapterFor('application').call('/attached-flags', type, data).then(function () {
                    selected.removeObject(item);
                    resolve(item);
                  }, function (error) {
                    reject({
                      error: error,
                      record: record
                    });
                  });
                }));
              }
            });
            var notifyTitle = i18n.t('components:modals.batch.xItems.notify.title', {
              count: batchCount
            });
            var notifyMsg = '';

            // see http://www.emberjs.com.cn/api/classes/RSVP.html#method_allSettled
            return Ember.RSVP.allSettled(recordsToUpdate, "Add/Delete flag#".concat(flagID, " on ").concat(batchCount, " ").concat(mainModel, " record(s)")).then(function (result) {
              var recordsNotModified = result.reduce(function (recordsNotModified, promise) {
                if (promise.state === 'rejected') {
                  // promise.reason.record = record (Ember.Object) - see before when we push in `recordsToDestroy`
                  recordsNotModified.push(promise.reason.record);
                  _this.set('notModifiedErrors', promise.reason.error.errors);
                }
                return recordsNotModified;
              }, []);

              // executed when all "recordsToUpdate" promises have been settled
              // result = array of the states of these promises
              //TLA : Le deflag est TOUJOURS un succès :D
              if (actionType === 'add' && recordsNotModified.length) {
                // so some promises have been rejected :'(
                var nbUpdated = batchCount - recordsNotModified.length;
                notifyMsg = i18n.t('components:modals.batch.xItems.notify.error.common') + ' ' + i18n.t('components:modals.batch.xItems.notify.error.details', {
                  count: nbUpdated
                });
                _this.set('notModified', Ember.A(recordsNotModified));
                _this.get('notify').error(notifyMsg, notifyTitle);
              } else {
                // all updated :D
                notifyMsg = i18n.t('components:modals.batch.xItems.notify.success', {
                  count: batchCount
                });
                _this.get('notify').success(notifyMsg, notifyTitle);
              }
            }).finally(function () {
              _this.send('uncheckAllCurrentSearch');
              // Refresh current route
              Ember.getOwner(_this).lookup("route:".concat(_this.get('router.currentRouteName'))).refresh();
            });
          }
        }];
        this.get('modalGlobalMessage').open();
      },
      /**
       * Action - Extract
       *
       * @event extract
       */
      extract: function extract(values) {
        var app = null;
        var type = null;
        if (typeof values.extractApp !== 'undefined') {
          app = this.extractApp.app;
          type = this.extractApp.type;
        }
        var selected = this.get('selected');
        var queryParams = this.queryParamsForExtract(selected, values);
        if (typeof values.extractOption !== 'undefined') {
          this.set('extractOption', values.extractOption);
        }
        queryParams['encoding'] = this.get('extractOption');
        if (typeof values.extractType !== 'undefined') {
          this.set('extractType', values.extractType);
          queryParams['extractType'] = values.extractType;
        }
        if (app !== null && type !== null) {
          queryParams['app'] = app;
          queryParams['type'] = type;
        }

        // urlTemplate use 'format' property to append it like an extension
        queryParams['format'] = this.get('extractFormat');
        var notifyTitle = this.get('i18n').t('components:modals.extract.xItems.notify.title', {
          count: selected.length
        });
        this.get('notify').info(this.get('i18n').t('components:modals.extract.xItems.notify.start'), notifyTitle);
        this.set('showConfirmExtract', false);
        if (typeof this.get('additionalParamsForAppNoCode') !== 'undefined') {
          this.callUrlForExtract(Ember.assign(this.get('additionalParamsForAppNoCode'), queryParams));
        } else {
          this.callUrlForExtract(queryParams);
        }
      },
      /**
       * Action - Export
       *
       * @event export
       */
      export: function _export(selectedExportType, exportProof) {
        var selected = this.get('selected');
        var mainModel = this.get('mainModel');
        var mainModelClass = this.get('mainModelClass');
        var adapter = this.store.adapterFor(mainModel);
        var queryParamsVar = this.get('queryParams');
        var queryParams = this.getProperties(queryParamsVar);
        if (selected.length > 0 && this.get('filteredExport')) {
          queryParams.keywords = selected.map(function (item /*, index, enumerable*/) {
            return mainModelClass.prefix + item;
          }).join(' ');
        }
        if (typeof selectedExportType !== 'undefined') {
          queryParams['exportToDownloadCenter'] = selectedExportType;
        } else {
          queryParams['exportToDownloadCenter'] = true;
        }
        if ((0, _exports2.containsProof)(selectedExportType)) {
          queryParams['exportProof'] = exportProof;
        }
        this.set('showConfirmExport', false);
        adapter.export(mainModel, queryParams);
      },
      closeExportModal: function closeExportModal() {
        this.set('showConfirmExport', false);
      },
      updateSearchFilters: function updateSearchFilters(filters) {
        var _this2 = this;
        Object.keys(filters).forEach(function (filterId) {
          _this2.send('updateFilter', filterId, filters[filterId]);
        });
      },
      /**
       * Colonnes configurables
       * Ouverture de la modale de param des colonnes
       * */
      onShowSettingsColumns: function onShowSettingsColumns() {
        this.set('showSettingsColumns', true);
      },
      /**
       * Colonnes configurables
       * Fermeture de la modale de param des colonnes
       * */
      onCloseSettingsColumns: function onCloseSettingsColumns() {
        this.set('showSettingsColumns', false);
      },
      /**
       * Colonnes configurables
       * @param {String} action action à réaliser
       * @param {String} column colonne concernée
       */
      updateGridColumn: function updateGridColumn(action, column) {
        var columnsModel = this.get('columnsModel');
        switch (action) {
          case 'remove':
            columnsModel.removeColumn(column);
            if (this.get('savedSearchId')) {
              this.set('savedSearchId', undefined);
            }
            this.send('updateFilter', 'columns', columnsModel.selectedColumnsQueryParams);
            break;
          case 'reinit':
            {
              // On reinitialise la largeur colonne du local storage
              var columnToReinit = columnsModel.getColumn(column);
              if (columnToReinit) columnToReinit.resetWidth();
              break;
            }
        }
      },
      /**
       * Colonnes configurables
       * - Fermeture de la modale de param des colonnes
       * - Mise à jour du filtre colomns
       * */
      onSaveSettingsColumns: function onSaveSettingsColumns(selectedColumns, withReinitColWidth) {
        this.set('showSettingsColumns', false);
        this.get('columnsModel').selectedColumns = selectedColumns;
        this.get('columnsModel').save(withReinitColWidth);
        if (JSON.stringify(this.get('columns')) !== JSON.stringify(this.get('columnsModel').selectedColumnsQueryParams)) {
          if (this.get('savedSearchId')) {
            this.set('savedSearchId', undefined);
          }
          this.remoteLogger.logFeatureEvent(_remoteLogger.FEATURE_F114_RESIZABLE_COLUMN, _remoteLogger.EVENT_CLICK, {
            route: this.router.currentURL,
            modal: this.get('columnsModel').model.module,
            type: 'savedSearchSettingsColumns',
            defaultConfig: this.get('columnsModel').hasDefaultConfig,
            defaultOrder: this.get('columnsModel').hasDefaultConfigWithDefaultOrder
          });
        }
        this.send('updateFilter', 'columns', this.get('columnsModel').selectedColumnsQueryParams);
      }
    }
  });
});