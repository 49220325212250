define("boondmanager/pods/boondmanager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TU1si+zx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"bml-authenticated-view \",[34,0]]]],[12],[2,\"\\n\\n\\t\"],[8,\"octane/bm-layout-sidebar/boond-manager\",[],[[],[]],null],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,1,\"bml-app-page\"],[12],[2,\"\\n\\t\\t\"],[10,\"header\"],[14,1,\"bml-page-header\"],[14,\"data-name\",\"section-page-header\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"accounts\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[10,\"div\"],[14,1,\"bml-breadcrumb\"],[12],[2,\"\\n\\t\\t\\t\\t\\t\"],[8,\"octane/bm-breadcrumb\",[],[[],[]],null],[2,\"\\n\\t\\t\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\t\\t\\t\"],[8,\"octane/bm-ajax-indicator\",[],[[],[]],null],[2,\"\\n\\n\\t\\t\\t\"],[10,\"div\"],[14,1,\"bml-user-tools\"],[12],[2,\"\\n\\n\\t\\t\\t\\t\"],[8,\"octane/bm-global-help\",[],[[],[]],null],[2,\"\\n\\n\\t\\t\\t\\t\"],[8,\"octane/bm-layout-user-menu/boond\",[],[[\"@model\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\n\\t\\t\"],[10,\"main\"],[14,1,\"bml-page-content\"],[12],[2,\"\\n\\t\\t\\t\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\n\\t\"],[10,\"div\"],[14,1,\"native-modal-area\"],[12],[13],[2,\"\\n\\n\\t\"],[8,\"octane/bm-btn/scroll-to-top\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hiddenMenuClass\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "boondmanager/pods/boondmanager/template.hbs"
  });
});