define("boondmanager/models/bankingaccount", ["exports", "boondmanager/models/base", "ember-data", "ember-data-copyable"], function (_exports, _base, _emberData, _emberDataCopyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BankingAccount = _base.default.extend(_emberDataCopyable.default, {
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    disabled: _emberData.default.attr('boolean'),
    display: _emberData.default.attr('boolean'),
    iban: _emberData.default.attr('string'),
    bic: _emberData.default.attr('string'),
    agencies: _emberData.default.hasMany('agency', {
      inverse: null
    }),
    connection: _emberData.default.belongsTo('bankingconnection'),
    agencyDisplayText: Ember.computed('i18n._locale', 'agencies.[]', function () {
      var agencies = this.get('agencies');
      if (agencies.length === 1) {
        return agencies.firstObject.name;
      } else if (agencies.length > 1) {
        return this.get('i18n').t('adminbankingconnections:view.accounts.agency', {
          count: agencies.length
        });
      } else {
        return this.get('i18n').t('adminbankingconnections:view.accounts.no_agency');
      }
    }).readOnly(),
    entityName: Ember.computed('name', 'title', function () {
      var title = this.get('title');
      return title && title.length ? title : this.get('name');
    })
  });
  BankingAccount.reopenClass({
    prefix: 'BNKACC'
  });
  BankingAccount.reopen({
    prefix: Ember.computed(function () {
      return BankingAccount.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BankingAccount;
});