define("boondmanager/models/bankingtransactionfragment", ["exports", "ember-data-model-fragments", "ember-data"], function (_exports, _emberDataModelFragments, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberDataModelFragments.default.Fragment.extend({
    originalID: _emberData.default.attr('string')
  });
});