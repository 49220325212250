define("boondmanager/controllers/base", ["exports", "boondmanager/utils", "ember-inflector", "boondmanager/utils/logger", "boondmanager/helpers/icon", "ember-copy", "jquery", "boondmanager/utils/search", "moment", "boondmanager/pods/components/bm-field/perimeter/component", "boondmanager/models/invoice", "boondmanager/models/payment"], function (_exports, _utils, _emberInflector, _logger, _icon, _emberCopy, _jquery, _search, _moment, _component, _invoice, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Contrôleur parent principal de l'application
   *
   * @class  BaseController
   * @extends Ember.Controller
   * @namespace  Ember
   * @module  Controllers
   */
  var _default = _exports.default = Ember.Controller.extend({
    /** SERVICES **/
    currentUser: Ember.inject.service('current-user'),
    router: Ember.inject.service(),
    rights: Ember.inject.service(),
    appTriggers: Ember.inject.service('app-triggers'),
    flagsService: Ember.inject.service('flags'),
    // "flagsService" name because we have "flags" QP prop in some routes
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    assignments: Ember.inject.service(),
    openProtocol: Ember.inject.service('open-protocol'),
    application: Ember.inject.controller(),
    settings: Ember.inject.service(),
    modalGlobalMessage: Ember.inject.service(),
    todolistRoute: null,
    /** PROPERTIES **/
    /**
     * Nom du module
     * Utile pour récupérer les droits globaux par module
     *
     * @property rightsModule
     * @type {null|string}
     * @default null
     */
    rightsModule: null,
    /**
     * Nom du modèle principale de la page courante
     * Utile pour les extracts, exports et autres actions de batchs
     *
     * @property mainModel
     * @type {null|string}
     * @default null
     */
    mainModel: null,
    /**
     * "(Un)Select All" checkbox state
     *
     * cf https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input#Specifications
     * cf https://css-tricks.com/indeterminate-checkboxes/
     *
     * checked  indeterminate
     *       O              O => 0 - none checked (unchecked)
     *       0              1 => 1 - NOT USED
     *       1              0 => 2 - all checked in all pages
     *       1              1 => 4 - some checked (indeterminate)
     */
    /**
     * Checked state of cbAll checkbox
     *
     * @property cbAllChecked
     * @type {boolean}
     * @default false
     */
    cbAllChecked: false,
    /**
     * Indeterminate state of cbAll checkbox
     *
     * @property cbAllIndeterminate
     * @type {boolean}
     * @default false
     */
    cbAllIndeterminate: false,
    /**
     * Used on page where a refresh after a successful deletion would trigger an Ember error
     *
     * @property refreshAfterDeletion
     * @type {boolean}
     * @default true
     */
    refreshAfterDeletion: true,
    /**
     * Get cb checked state: true if one or more is checked
     *
     * @uses cbAllChecked
     * @uses cbAllIndeterminate
     *
     * @property cbChecked
     * @type {Boolean}
     * @readOnly
     */
    cbChecked: Ember.computed.or('cbAllChecked', 'cbAllIndeterminate').readOnly(),
    /**
     * hook for execute function before deletion
     *
     * @property beforeDeletion
     * @type {boolean}
     * @default false
     */
    beforeDeletion: false,
    /**
     * hook for execute function after deletion
     *
     * @property afterDeletion
     * @type {boolean}
     * @default false
     */
    afterDeletion: false,
    /**
     * cbAllTargets checkboxes selector
     *
     * @property cbAllTargetsSelector
     * @type {string}
     * @default '.bmc-table-list-check-checkbox'
     */
    cbAllTargetsSelector: '.bmc-table-list-check-checkbox',
    /**
     * Array of 'cbAllTargets' selected checkbox's IDs
     *
     * This array is initialized to an empty Èmber.Array in Controller.init() method
     *
     * @property selected
     * @type {Ember.Array}
     * @default null
     */
    selected: null,
    /**
     * checklistId
     *
     * the table checklist ID if defined
     *
     * @property checklistId
     * @type {string}
     * @default null
     */
    checklistId: null,
    /**
     * Message de suppression à afficher dans la modale
     *
     * @property message
     * @type {string}
     * @default ''
     */
    message: '',
    /**
     * Liste des items qui n'ont pas été supprimés lors de l'appel à une suppression en masse depuis une liste
     *
     * @property notDeleted
     * @type {Ember.Object[]}
     * @default null
     */
    notDeleted: null,
    /**
     * Liste des items qui n'ont pas été modifiés lors de l'appel à une modification en masse depuis une liste
     *
     * @property notModified
     * @type {Ember.Object[]}
     * @default null
     */
    notModified: null,
    /**
     * Liste des items qui n'ont pas été dupliqués lors de l'appel à une duplication en masse depuis une liste
     *
     * @property notDuplicated
     * @type {Ember.Object[]}
     * @default null
     */
    notDuplicated: null,
    /**
     * Query parameter: number of results per page
     *
     * @property maxResults
     * @type {Number}
     * @default DEFAULT_ROWS_BY_PAGE
     */
    maxResults: _search.DEFAULT_ROWS_BY_PAGE,
    /**
     * Query parameter: current page displayed
     *
     * @property page
     * @type {Number}
     * @default 1
     */
    page: 1,
    /**
     * Nombre total de lignes pour les résultats de recherche
     *
     * @property totalRows
     * @type {Number}
     * @default -1
     */
    totalRows: -1,
    /**
     * Number of results expected
     *
     * @property lastValidTotal
     * @type {Number}
     * @default 0
     */
    lastValidTotal: 0,
    //CAUTION: currentPath & currentRouteName are undefined in init()
    /**
     * Chemin de la route actuelle
     *
     * @property currentPath
     * @type {String}
     */
    currentPath: Ember.computed.alias('application.currentPath'),
    /**
     * Nom de la route actuelle
     *
     * @property currentRouteName
     * @type {String}
     */
    currentRouteName: Ember.computed.alias('application.currentRouteName'),
    /**
     * Permet d'enclencher une mise à jour des @params utilisé dans les Filtres du turfu
     * Etant suivie dans les filtres via "did-update", cela lancera à nouveau l'initValue des filtres
     * {{did-update this.initFilterValues @params}}
     *
     * {Boolean} refreshActiveQueryParams
     */
    refreshActiveQueryParams: false,
    /**
     * Chargement du modèle en cours sur la page courante
     *
     * @property currentDataIsLoading
     * @type {Boolean}
     * @default false
     */
    currentDataIsLoading: false,
    /**
     * Paramètres pour les actions de batch (delete, extract, export, ...)
     *
     * @property cbAllChecked
     * @type {Object}
     * @default {}
     */
    batchParams: {},
    /**
     * Affichage de la modale de confirmation du lancement d'un batch
     *
     * @property showConfirmBatch
     * @type {boolean}
     * @default false
     */
    showConfirmBatch: false,
    /** COMPUTED **/
    /**
     * Main model Class
     *
     * @property mainModelClass
     * @see mainModel
     * @type {Ember.model}
     * @default undefined
     */
    mainModelClass: Ember.computed('mainModel', function () {
      var mainModel = this.get('mainModel');
      return this.store.modelFor(mainModel);
    }).readOnly(),
    /**
     * Liste des queryParams utilisés dans les Filtres du turfu
     */
    activeQueryParams: Ember.computed('queryParams', 'saveSearch', 'loadSearch', 'router.currentRoute.queryParams', 'refreshActiveQueryParams', function () {
      var _this = this;
      var activeQueryParams = {};
      this.get('queryParams').concat(['saveSearch', 'loadSearch']).forEach(function (param) {
        activeQueryParams[param] = _this.get(param);
      });
      return activeQueryParams;
    }).readOnly(),
    /**
     * Current user rights
     */
    advancedRights: Ember.computed('rights.globalLastUpdate', 'rightsModule', function () {
      return this.get('rightsModule') !== null ? this.get('rights').getAdvancedRightsForModule(this.get('rightsModule')) : null;
    }).readOnly(),
    massFlags: Ember.computed('flagsService.massFlags.[]', function () {
      return this.get('flagsService.massFlags');
    }).readOnly(),
    /**
     * construction de la liste des composants à appeler sur cette page
     * @return {Ember.A}
     */
    appsComponents: Ember.computed('currentUser.apps.[]', 'router.{currentRouteName,currentURL}', function () {
      var _this2 = this;
      var ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      var promise = this.get('currentUser').then(function (currentUser) {
        return currentUser.get('apps').then(function (currentUserApps) {
          var apps = [];
          var currentRoute = _this2.get('router.currentRouteName');
          var currentURL = _this2.get('router.currentURL');
          currentUserApps.map(function (app) {
            app.get('iFramesSetting').map(function (setting) {
              /*
               * selon la valeur de `page`:
               * 1. module => composant à afficher sur la recherche
               * 2. module/{id} => composant à afficher sur tout les onglets d'une fiche du module
               * 3. module/{id}/onglet => composant à afficher sur l'onglet de la page
               */
              var _setting$page$replace = setting.page.replace('-', '').split('/'),
                _setting$page$replace2 = _slicedToArray(_setting$page$replace, 3),
                module = _setting$page$replace2[0],
                id = _setting$page$replace2[1],
                onglet = _setting$page$replace2[2];
              var routeSetting = '';
              if (module === 'dashboard') {
                routeSetting = "private.".concat(module);
              } else {
                if (onglet) {
                  if (module === 'projects' && onglet === 'deliveriesgroupments') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".deliveries");
                  } else if (module === 'projects' && onglet === 'productivity') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".consumption");
                  } else if (module === 'resources' && onglet === 'deliveriesinactivities') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".deliveries");
                  } else {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".").concat(onglet);
                  }
                } else if (id) {
                  routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module));
                } else {
                  routeSetting = "private.".concat(module, ".index");
                }
              }
              if (currentRoute.indexOf(routeSetting) === 0 && (app.get('isNative') || app.get('isIFrameCompatible'))) {
                apps.push({
                  component: app.get('isNative') ? app.get('appComponent') : 'octane/bm-app/component/iframe',
                  settings: setting,
                  model: app,
                  fn: setting.function,
                  currentUrl: currentURL
                });
              }
            });
          });
          _logger.default.debug("[apps-integration-controller mixin] ".concat(_this2, " - liste des composants \xE0 appeler"), apps);
          return Ember.A(apps).sort(function (a, b) {
            var aW = a.settings.isHalfWidth;
            var bW = b.settings.isHalfWidth;
            if (aW === bW) {
              return 0;
            } else {
              return aW === true ? 1 : -1;
            }
          });
        });
      });
      return ArrayPromiseProxy.create({
        promise: promise
      });
    }).readOnly(),
    appsButtons: Ember.computed('currentUser.apps.[]', 'router.{currentRouteName,currentURL}', function () {
      var _this3 = this;
      var ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      var promise = this.get('currentUser').then(function (currentUser) {
        return currentUser.get('apps').then(function (currentUserApps) {
          var apps = [];
          var currentRoute = _this3.get('router.currentRouteName');
          var currentURL = _this3.get('router.currentURL');
          currentUserApps.map(function (app) {
            app.get('buttonsSetting').map(function (setting) {
              /*
               * selon la valeur de `page`:
               * 1. module => composant à afficher sur la recherche
               * 2. module/{id} => composant à afficher sur tout les onglets d'une fiche du module
               * 3. module/{id}/onglet => composant à afficher sur l'onglet de la page
               */
              var _setting$page$replace3 = setting.page.replace('-', '').split('/'),
                _setting$page$replace4 = _slicedToArray(_setting$page$replace3, 3),
                module = _setting$page$replace4[0],
                id = _setting$page$replace4[1],
                onglet = _setting$page$replace4[2];
              var routeSetting = '';
              if (module === 'dashboard') {
                routeSetting = "private.".concat(module);
              } else {
                if (onglet) {
                  if (module === 'projects' && onglet === 'deliveriesgroupments') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".deliveries");
                  } else if (module === 'projects' && onglet === 'productivity') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".consumption");
                  } else if (module === 'resources' && onglet === 'deliveriesinactivities') {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".deliveries");
                  } else {
                    routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module), ".").concat(onglet);
                  }
                } else if (id) {
                  routeSetting = "private.".concat(module, ".").concat((0, _emberInflector.singularize)(module));
                } else {
                  routeSetting = "private.".concat(module, ".index");
                }
              }
              if (currentRoute.indexOf(routeSetting) === 0 && (app.get('isNative') || app.get('isIFrameCompatible'))) {
                apps.push({
                  id: app.get('id'),
                  app: app,
                  name: setting.title,
                  fn: setting.function,
                  currentUrl: currentURL
                });
              }
            });
          });
          _logger.default.debug("[apps-integration-controller mixin] ".concat(_this3, " - liste des boutons \xE0 appeler"), apps);
          return Ember.A(apps);
        });
      });
      return ArrayPromiseProxy.create({
        promise: promise
      });
    }).readOnly(),
    appsTabs: Ember.computed('currentUser.apps.[]', 'router.currentRouteName', function () {
      var _this4 = this;
      var ArrayPromiseProxy = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
      var promise = this.get('currentUser').then(function (currentUser) {
        return currentUser.get('apps').then(function (currentUserApps) {
          var apps = [];
          var currentRoute = _this4.get('router.currentRouteName').split('.').slice(0, -1).join('.');
          currentUserApps.filter(function (app) {
            return app.get('isModuleNoCode') && app.get('module');
          }).map(function (app) {
            var routeSetting = 'private.' + app.get('module') + '.' + (0, _emberInflector.singularize)(app.get('module'));
            if (currentRoute.indexOf(routeSetting) === 0 && app.get('isModuleNoCode')) {
              apps.push(app);
            }
          });
          _logger.default.debug("[apps-integration-controller mixin] ".concat(_this4, " - liste des onglets \xE0 ajouter"), apps);
          return Ember.A(apps);
        });
      });
      return ArrayPromiseProxy.create({
        promise: promise
      });
    }).readOnly(),
    // == DataTable - Checkbox Management ======================================
    /**
     * Get checked state of all search
     *
     * @uses cbAllChecked
     * @uses cbAllIndeterminate
     *
     * @property cbAllSearchChecked
     * @type {Boolean}
     * @readOnly
     */
    cbAllSearchChecked: Ember.computed('cbAllChecked', 'cbAllIndeterminate', function () {
      return this.get('cbAllChecked') && !this.get('cbAllIndeterminate');
    }).readOnly(),
    /**
     * Disabled checkbox select All if model dosnt have data.
     *
     * @property disabledSelectAll
     * @type {Boolean}
     * @readOnly
     */
    disabledSelectAll: Ember.computed('model.length', function () {
      return this.get('model.length') === 0;
    }).readOnly(),
    disabledMailTo: Ember.computed('selected.[]', function () {
      return Ember.isEmpty(this.listMailSelected());
    }).readOnly(),
    total: Ember.computed('model.meta.totals.rows', 'totalRows', {
      get: function get() {
        if (this.totalRows > -1) return this.totalRows;

        // Pagination dans reporting
        if (this.get('model.meta.totals.dependsOn')) {
          this.set('lastValidTotal', this.get('model.meta.totals.dependsOn'));
        }
        //GRI : Prevents totals from "blinking"
        else if (!isNaN(this.get('model.meta.totals.rows'))) {
          this.set('lastValidTotal', this.get('model.meta.totals.rows'));
        }
        return this.lastValidTotal;
      },
      set: function set(_, value) {
        this.set('totalRows', value);
        return value;
      }
    }),
    /**
     * Number of pages
     *
     * @property totalPages
     * @type {number}
     */
    totalPages: Ember.computed('total', 'maxResults', 'maxCompanies', 'maxProjects', 'maxResources', 'billingDisplay', function () {
      var maxResults = this.get('maxCompanies') || this.get('maxProjects') || this.get('maxResources') || this.get('maxResults');
      return (0, _search.calculateNbPages)(this.get('total'), maxResults);
    }).readOnly(),
    currentMainRouteName: Ember.computed('currentRouteName', function () {
      var currentRouteNames = this.get('currentRouteName').split('.');
      return currentRouteNames.filter(function (name) {
        return name !== 'private' && name !== 'index';
      }).join('.');
    }).readOnly(),
    /**
     * Selected Perimeters
     *
     * @property selectedPerimeters
     * @observes perimeterAgencies, perimeterBusinessUnits, perimeterManagers, perimeterPoles
     * @type {String[]}  List all selected perimeters ids
     * @readOnly
     */
    selectedPerimeters: Ember.computed('perimeterAgencies', 'perimeterBusinessUnits', 'perimeterManagers', 'perimeterPoles', function () {
      var _this5 = this;
      var selectedPerimeters = [];
      _component.defaultPerimeterTypes.forEach(function (perimeterType) {
        var queryParamsVar = "perimeter".concat(Ember.String.capitalize(perimeterType));
        var currentValues = _this5.get(queryParamsVar);
        if (currentValues && Ember.isArray(currentValues)) {
          currentValues.forEach(function (id) {
            selectedPerimeters.push((0, _component.buildPerimeterValue)(id, perimeterType));
          });
        }
      });
      return selectedPerimeters;
    }).readOnly(),
    /**
     * Is current user (manager) is the only selected manager in perimeters select
     *
     * @property isCurrentUserPerimeter
     * @type {Boolean}
     * @readOnly
     */
    isCurrentUserPerimeter: Ember.computed('currentUser.id', 'perimeterManagers', function () {
      // this CP will be reload when currentUser will be resolved because id will be updated from 'undefined' to its resolved value.
      var currentUserID = this.get('currentUser.id'); // 'undefined' if currentUser (a promise) is not resolved
      var perimeterAgencies = this.get('perimeterAgencies');
      var perimeterBusinessUnits = this.get('perimeterBusinessUnits');
      var perimeterManagers = this.get('perimeterManagers');
      var perimeterPoles = this.get('perimeterPoles');
      return perimeterManagers && Ember.get(perimeterManagers, 'length') === 1 && perimeterManagers.indexOf(currentUserID) !== -1 && perimeterAgencies && Ember.get(perimeterAgencies, 'length') === 0 && perimeterBusinessUnits && Ember.get(perimeterBusinessUnits, 'length') === 0 && perimeterPoles && Ember.get(perimeterPoles, 'length') === 0;
    }).readOnly(),
    /** METHODS **/
    /**
     * Action exécutée avant l'exécution du batch
     * @param items
     * @return Promise
     */
    onBeforeBatch: function onBeforeBatch(items) {
      return Ember.RSVP.Promise.resolve(items);
    },
    /**
     * Action exécutée pour chaque action du batch réalisée avec succès pour chaque item
     * @param model
     * @return {Promise}
     */
    onBatchSuccess: function onBatchSuccess(model) {
      return Ember.RSVP.Promise.resolve(model);
    },
    /**
     * Action exécutée pour chaque action du batch en échec pour chaque item
     * @param error
     * @return {Promise}
     */
    onBatchError: function onBatchError(error) {
      return Ember.RSVP.Promise.resolve(error);
    },
    /**
     * Does current model must be retrieved from backend API or not before executing batch ?
     * @param modelName
     * @returns {boolean}
     */
    retrieveModelBeforeBatch: function retrieveModelBeforeBatch(modelName) {
      var models = ['invoice'];
      return models.includes(modelName);
    },
    listMailSelected: function listMailSelected() {
      var selected = this.get('selected');
      if (!selected || selected.length == 0) {
        return;
      }
      var mainModel = this.get('mainModel');
      var store = this.store;
      var email = '';
      selected.forEach(function (item) {
        var record = store.peekRecord(mainModel, item);
        var userMail = record.get('email1');
        if (!Ember.isBlank(userMail)) {
          email += "".concat(userMail, ",");
        }
      });
      return email.substr(0, email.length - 1);
    },
    /**
     * Initialize flags for assignment (launched by setupController of current module)
     */
    initMassFlags: function initMassFlags() {
      this.get('flagsService').initFlags();
    },
    /**
     * Get IDs of all 'cbAllTargets' checkboxes in current displayed page
     *
     * @uses Ember.A
     *
     * @method _getCurrentPageCbIds
     * @return {Ember.Array}
     * @private
     */
    _getCurrentPageCbIds: function _getCurrentPageCbIds($parentElement) {
      var $targets;
      if ($parentElement) {
        $targets = $parentElement.find(this.get('cbAllTargetsSelector'));
      } else {
        $targets = (0, _jquery.default)(this.get('cbAllTargetsSelector'));
      }
      return Ember.A($targets.map(function (index, item) {
        var id = (0, _jquery.default)(item).val();

        //TSI Miky : Bug ie 11 et ember: https://github.com/emberjs/ember.js/issues/15203
        if (id === 'on') {
          id = (0, _jquery.default)(item).data('id').toString();
        }
        return id;
      }).get());
    },
    /**
     * Update CbAllIndeterminate and selected array when all search is selected
     *
     * @method _updateCbAllIndeterminateAndSelected
     * @private
     */
    _updateCbAllIndeterminateAndSelected: function _updateCbAllIndeterminateAndSelected(cbAllChecked, $parentElement, checklistId) {
      var _this6 = this;
      // Ember.run.next is important here. Without it, DOM cball checked property
      // was not sync with cbAllIndeterminate CP... To see this, remove next
      // line "Ember.run.next(...)"" and uncomment -- TEST -- after
      // TODO: Explore Ember Run Loop, how it realy works!!!!
      Ember.run.next(this, function () {
        var selected = _this6.get('selected');
        var $selectable;
        if ($parentElement) {
          $selectable = $parentElement.find(_this6.get('cbAllTargetsSelector'));
        } else {
          $selectable = (0, _jquery.default)(_this6.get('cbAllTargetsSelector'));
        }
        var propertiesToSet = {};
        propertiesToSet.cbAllChecked = cbAllChecked;
        if (selected.length === $selectable.length) {
          propertiesToSet.cbAllIndeterminate = false;
        } else if (selected.length === 0) {
          propertiesToSet.cbAllIndeterminate = false;
          propertiesToSet.cbAllChecked = false;
        } else {
          propertiesToSet.cbAllIndeterminate = cbAllChecked;
        }
        // Cas où on a définit un checklist id sur la table list
        // Cela permets de faire fonctionner plusieurs table list sur la même page
        if (checklistId) {
          if (propertiesToSet.cbAllChecked && !propertiesToSet.cbAllIndeterminate) {
            // si tout est coché et pas dans l'état Indeterminate
            // on coche la barre de fer relative à notre checklistId
            (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + checklistId + ']').prop('checked', true);
            (0, _jquery.default)('[data-type=checkall][data-checklistid=' + checklistId + ']').prop('checked', true);
          } else if (propertiesToSet.cbAllIndeterminate) {
            // si dans l'état Indeterminate
            // on coche l'état Indeterminate dans la barre de fer relative à notre checklistId
            (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + checklistId + ']').prop('indeterminate', true);
            (0, _jquery.default)('[data-type=checkall][data-checklistid=' + checklistId + ']').prop('indeterminate', true);
          } else {
            // sinon on met checked et indeterminate à false pour notre barre de fer
            (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + checklistId + ']').prop({
              checked: false,
              indeterminate: false
            });
            (0, _jquery.default)('[data-type=checkall][data-checklistid=' + checklistId + ']').prop({
              checked: false,
              indeterminate: false
            });
          }
        } else {
          _this6.setProperties(propertiesToSet);
        }
      });
    },
    _uncheckOtherDatalistCheckboxs: function _uncheckOtherDatalistCheckboxs($tableParent) {
      // deselect all others datatable-selection checkboxs : Only one table selected for "barre de FAIRE"
      var $allOtherDatalistCheckbox = (0, _jquery.default)('.bmc-table-list input' + this.get('cbAllTargetsSelector'));
      $allOtherDatalistCheckbox.each(function (idx, datalistCheckbox) {
        if ($tableParent.length && !$tableParent[0].contains(datalistCheckbox) && datalistCheckbox.checked !== false) {
          datalistCheckbox.checked = false;
          datalistCheckbox.click();
        }
      });
    },
    /**
     * Get entity from ID for batch actions (batch, delete, update, updateFlags...)
     * Override if necessary
     * @param id
     * @param modelName
     * @returns {*|DS.Model}
     */
    getEntityFromId: function getEntityFromId(id, modelName) {
      return this.get('store').peekRecord(modelName, id);
    },
    /**
     Update the underneath property hence the query param
    	 @method updateQueryParam
     @param param {String} the name of the property to update
     @param value {String} the new value of the property to update
     */
    updateQueryParam: function updateQueryParam(param, value) {
      this.send('updateFilter', param, value);
    },
    /**
     Decounce the query param update
    	 @method paramUpdate
     @param context {Object} the name of the property to update
     @param debounceParamName {String} the name of the property to update
     in the format debounce_paramName
     */
    paramUpdate: function paramUpdate(context, debounceParamName, value) {
      var originalParamName = debounceParamName.substr(9, debounceParamName.length);
      Ember.run.debounce(this, this.updateQueryParam, originalParamName, value, this.get("debounceQueryParams.".concat(originalParamName)));
    },
    /**
     Create an observer for each of the properties in the debounceQueryParams object
    	 @method setupDebounceQueryParams
     */
    setupDebounceQueryParams: function setupDebounceQueryParams() {
      var _this7 = this;
      var debounceQueryParams = this.get('debounceQueryParams');
      var _loop = function _loop(param) {
        Ember.run.schedule('actions', _this7, function () {
          var debouncedCP = Ember.computed(param, {
            get: function get() {
              return this.get(param);
            },
            set: function set(_, value) {
              this.paramUpdate(this, "debounce_".concat(param), value);
              return value;
            }
          });
          Ember.defineProperty(_this7, "debounce_".concat(param), debouncedCP);
          _this7.send('updateFilter', "debounce_".concat(param), _this7.get(param));
        });
      };
      for (var param in debounceQueryParams) {
        _loop(param);
      }
    },
    /**
     * Hook déclenché lorsque la sélection sur une vue liste change
     * - check/uncheck d'une valeur
     * - checkAll dans le header
     * - uncheckAll
     *
     * @param {Array} selected Sélection courante
     * @param {Object} e Event Object
     * @return {Array} Sélection courante
     */
    onSelectionChange: function onSelectionChange(selected /*, e*/) {
      return selected;
    },
    /** OBSERVERS **/
    checkPerimeter: Ember.observer('perimeterAgencies', 'perimeterBusinessUnits', 'perimeterManagers', 'perimeterPoles', function () {
      if (!Ember.isArray(this.get('perimeterAgencies'))) {
        this.set('perimeterAgencies', []);
      }
      if (!Ember.isArray(this.get('perimeterBusinessUnits'))) {
        this.set('perimeterBusinessUnits', []);
      }
      if (!Ember.isArray(this.get('perimeterManagers'))) {
        this.set('perimeterManagers', []);
      }
      if (!Ember.isArray(this.get('perimeterPoles'))) {
        this.set('perimeterPoles', []);
      }
    }),
    /** CONTROLLER LIFECYCLE **/
    // == /DataTable - Checkbox Management =====================================
    init: function init() {
      // Set `selected` property to an empty (Ember) array on init
      this.set('selected', Ember.A([]));
      this.set('checklistId', null);
      this.set('notDeleted', Ember.A([]));
      this.set('notModified', Ember.A([]));
      this.set('notModifiedErrors', Ember.A([]));
      this.set('notDuplicated', Ember.A([]));
      this.set('batchParams', {});
      this.set('showConfirmBatch', false);
      this.setupDebounceQueryParams();

      // Check mainModel property on init : mainModel must be defined in controller container!
      var mainModel = this.get('mainModel');

      /* CAUTION: we only test if mainModel not null because mainModel could be
                   an empty string like in crm/index route before crmType is set and a transition
                   is made */
      (false && !(Ember.typeOf(mainModel) === 'string' || mainModel === null) && Ember.assert('"mainModel" name must be set to string or null!', Ember.typeOf(mainModel) === 'string' || mainModel === null));
      this._super.apply(this, arguments);
    },
    /** ACTIONS **/
    actions: {
      /**
       * Hack to change a relationship and dirtify the model
       * @FixMe : The model will stay dirty as long as it isn't saved and you won't be able to rollback. This should be
       * 			refactored when we upgrade to Ember 3
       *
       * @param {Ember.Model} model         Ember model instance which own `relationship`
       *                                      and become 'dirty'
       * @param {String}      relationship  model's relationship to set
       * @param {*}           value         value to set
       */
      changeRelationship: function changeRelationship(model, relationship, value) {
        (0, _utils.changeRelationship)(model, relationship, value);
      },
      // == DataTable - Checkbox Management ==================================
      /**
       * Action - uncheck all current Search
       *
       * @event uncheckAllCurrentSearch
       */
      uncheckAllCurrentSearch: function uncheckAllCurrentSearch() {
        this.setProperties({
          cbAllChecked: false,
          cbAllIndeterminate: false
        });
        // Cas où on a définit un checklist id sur la table list
        // Cela permets de faire fonctionner plusieurs table list sur la même page
        // on décoche tout si le tableau est relatif à un checklistid
        (0, _jquery.default)('[data-type=checkall]').each(function (key, el) {
          if ((0, _jquery.default)(el).data('checklistid')) {
            (0, _jquery.default)(el).prop('indeterminate', false);
            (0, _jquery.default)(el).prop('checked', false);
          }
        });
        this.get('selected').clear();
        this.onSelectionChange(this.get('selected'), null);
        return false;
      },
      cbUncheckAll: function cbUncheckAll(event) {
        event.stopPropagation();
        this.send('uncheckAllCurrentSearch');
        return false;
      },
      /**
       * Action - manage click on cbAll checkbox
       *
       * @uses _getCurrentPageCbIds
       * @uses _updateCbAllIndeterminateAndSelected
       *
       * @event onCbAllClick
       */
      onCbAllClick: function onCbAllClick(e) {
        var $el = (0, _jquery.default)(e.currentTarget);
        var $tableParent = $el.closest('.bmc-table-list');
        e.stopPropagation();
        if ($el.data('checklistid')) {
          // Cas où on a définit un checklist id sur la table list
          // Cela permets de faire fonctionner plusieurs table list sur la même page
          if (this.get('checklistId') != $el.data('checklistid')) {
            // le checklist id a changé, cela signifie que l'user a cliqué sur une checkbox all d'un autre tableau
            // on remet les valeurs dans le dom à false : checked, indeterminate
            // on définit le changement de checklistId
            this.set('selected', Ember.A([]));
            (0, _jquery.default)('[data-type=checkall][data-checklistid!=' + $el.data('checklistid') + ']').prop({
              checked: false,
              indeterminate: false
            });
            (0, _jquery.default)('[data-type=check][data-checklistid!=' + $el.data('checklistid') + ']').prop({
              checked: false,
              indeterminate: false
            });
            this.set('checklistId', $el.data('checklistid'));
          }
        } else {
          this._uncheckOtherDatalistCheckboxs($tableParent);
        }
        var ids = this._getCurrentPageCbIds($tableParent);
        var selected = this.get('selected');

        // si tout n'est pas coché et qu'on est en train de cocher, on coche tout
        if (!e.target.checked && selected.length && selected.length !== ids.length) {
          e.target.checked = true;
        }
        if (e.target.checked && !e.target.indeterminate) {
          selected.addObjects(ids);
        } else {
          var idsInSelected = ids.map(function (id) {
            return selected.indexOf(id) !== -1;
          });
          if (idsInSelected.length) {
            selected.removeObjects(ids);
          }
        }
        var cbAllChecked = e.target.checked;
        this._updateCbAllIndeterminateAndSelected(cbAllChecked, $tableParent, this.get('checklistId'));
        this.onSelectionChange(selected, e);
      },
      /**
       * Action - manage click on cbAllTargets checkboxes
       *
       * @uses cbAllTargetsSelector
       * @uses _getCurrentPageCbIds
       * @uses _updateCbAllIndeterminateAndSelected
       *
       * @event onCbClick
       * @param {Object} e Event Object
       */
      onCbClick: function onCbClick(el, e) {
        (false && !(typeof el.checked !== 'undefined') && Ember.assert("".concat(this, ".actions.onCbClick - 'el' does not have a 'checked' property! Bad DOM element passed as argument?"), typeof el.checked !== 'undefined'));
        e.stopPropagation();
        // manage 'selected' array
        var $cb = (0, _jquery.default)(el);
        var checked = $cb.prop('checked');
        var id = $cb.val();

        //TSI Miky : Bug ie 11 et ember: https://github.com/emberjs/ember.js/issues/15203
        if (id === 'on') {
          id = $cb.data('id').toString();
        }
        var selected = this.get('selected');
        var idx = selected.indexOf(id);
        var $tableParent = (0, _jquery.default)(e.currentTarget).closest('.bmc-table-list');
        var hasChecklistId = false;
        // Cas où on a définit un checklist id sur la table list
        // Cela permets de faire fonctionner plusieurs table list sur la même page
        if ($cb.data('checklistid')) {
          hasChecklistId = true;
          if (this.get('checklistId') != $cb.data('checklistid')) {
            // le checklist id a changé, cela signifie que l'user a cliqué sur une checkbox d'un autre tableau
            // on remet les valeurs dans le dom à false : checked, indeterminate
            // on définit le changement de checklistId
            this.set('selected', Ember.A([]));
            selected = this.get('selected');
            idx = selected.indexOf(id);
            (0, _jquery.default)('[data-type=checkall][data-checklistid!=' + $cb.data('checklistid') + ']').prop({
              checked: false,
              indeterminate: false
            });
            (0, _jquery.default)('[data-type=check][data-checklistid!=' + $cb.data('checklistid') + ']').prop({
              checked: false,
              indeterminate: false
            });
            this.set('checklistId', $cb.attr('data-checklistid'));
          }
        }
        if (checked) {
          // => on va décoché la CB
          if (idx > -1) {
            selected.removeAt(idx);
          }
          // Cas où on a définit un checklist id sur la table list
          // Cela permets de faire fonctionner plusieurs table list sur la même page
          if (hasChecklistId) {
            // on gère la décoche directement dans le dom
            if (selected.length === 0) {
              // checkbox qui coche/decoche tout le tableau
              (0, _jquery.default)('[data-type=checkall][data-checklistid=' + $cb.data('checklistid') + ']').prop('checked', false);
              // barre de fer
              (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + $cb.data('checklistid') + ']').prop('checked', false);
            }
            this.set('selected', selected);
          } else {
            // comportement par défaut
            if (this.get('cbAllSearchChecked')) {
              this.set('cbAllIndeterminate', true);
              var ids = this._getCurrentPageCbIds($tableParent).removeObject(id);
              this.set('selected', ids);
            }
          }
        } else {
          if (!hasChecklistId) {
            // on a pas de checklist Id de definit, alors on décoche
            // les autres checkbox
            this._uncheckOtherDatalistCheckboxs($tableParent);
          }

          // on va coché la CB, on regarde si elle est déjà dans le tableau
          if (idx === -1) {
            // pas dans le tableau, on l'ajoute
            selected.pushObject(id);
            this.set('selected', selected);

            // Cas où on a définit un checklist id sur la table list
            // Cela permets de faire fonctionner plusieurs table list sur la même page
            if (hasChecklistId) {
              if ((0, _jquery.default)('[data-type=check][data-checklistid=' + $cb.data('checklistid') + ']').length != selected.length) {
                // si une ou plusieurs mais pas tout, on passe la checkbox en indeterminate pour la checkbox qui coche/decoche tout le tableau
                (0, _jquery.default)('[data-type=checkall][data-checklistid=' + $cb.data('checklistid') + ']').prop('indeterminate', true);
                // si une ou plusieurs mais pas tout, on passe la checkbox en indeterminate dans la barre de fer
                (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + $cb.data('checklistid') + ']').prop('indeterminate', true);
              } else {
                // si tout est coché, on retire l'état indeterminate dans la checkbox qui coche/decoche tout le tableau
                (0, _jquery.default)('[data-type=checkall][data-checklistid=' + $cb.data('checklistid') + ']').prop('indeterminate', false);
                // si tout est coché, on retire l'état indeterminate dans la barre de fer
                (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + $cb.data('checklistid') + ']').prop('indeterminate', false);
                // si tout est coché, on coche la checkbox qui coche/decoche tout le tableau
                (0, _jquery.default)('[data-type=checkall][data-checklistid=' + $cb.data('checklistid') + ']').prop('checked', true);
                // si tout est coché, on coche la checkbox de la barre de fer
                (0, _jquery.default)('[data-type=selectionBar][data-checklistid=' + $cb.data('checklistid') + ']').prop('checked', true);
              }
            }
          } else {
            // déjà dans le tableau, bizarre... juste un warning : peut-être un pb plus profond...
            _logger.default.warn("This checkbox already exists at ".concat(idx, " index in CP 'selected'"));
          }
        }
        this._updateCbAllIndeterminateAndSelected(true, $tableParent, this.get('checklistId'));
        this.onSelectionChange(selected, e);
      },
      // == /DataTable - Checkbox Management =================================
      /**
       * Action - Delete
       *
       * @event delete
       * @property {mixed} context Contexte depuis lequel
       */
      delete: function _delete(e, context) {
        var _this8 = this;
        this.send('emptyNotDeleted');
        _logger.default.debug('datatable delete');
        var selected = this.get('selected');
        if (!selected || selected.length === 0) {
          return;
        }

        /**
         * Utilisation d'un contexte pour palier au souci de l'ancienne utilisation de la mixin datatable-selection-controller
         * depuis les composants. On passera un contexte dans ce cas la qui est la variable this et qui permet de ne pas trop
         * modifier les composants existants pour la suppression des mixins
         * TODO: revoir la gestion des datatables pour créer un composant englobant et ramener la logique métier de la datatable dans ce composant
         * @type {*|event:delete}
         */
        context = context || this;
        var mainModel = typeof context.mainModel !== 'undefined' ? context.mainModel : context.get('mainModel');
        var i18n = this.get('i18n');
        var deleteCount = selected.length;
        var i18nNamespace = '';
        if (typeof context.i18nNamespace !== 'undefined') {
          i18nNamespace = context.i18nNamespace;
        } else if (this.get('i18nNamespace')) {
          i18nNamespace = this.get('i18nNamespace');
        } else {
          i18nNamespace = i18n.namespacize(mainModel);
        }
        var namespace = [i18nNamespace, 'common']; // ajout d'un fallback vers common pour ne pas faire planter l'app
        // Some modules have a detailed warning
        var additionalMessage = '';
        if (i18n.exists('modals.delete.keepObjects.message', {
          ns: namespace
        })) {
          additionalMessage = "<div class=\"bmc-alerts bmc-alerts-warning\">\n\t\t\t\t\t<div class=\"bmc-alert-icon-container\">\n\t\t\t\t\t".concat((0, _icon.icon)(['bmi-warning-alert']), "\n\t\t\t\t\t</div>\n\t\t\t\t\t<div>\n\t\t\t\t\t<p>\n\t\t\t\t\t").concat(i18n.t('modals.delete.keepObjects.message', {
            ns: namespace
          }), "\n\t\t\t\t\t</p>");
          if (i18n.exists('modals.delete.keepObjects.list', {
            ns: namespace
          })) {
            additionalMessage += i18n.t('modals.delete.keepObjects.list', {
              ns: namespace
            });
          }
          additionalMessage += '</div>';
        }
        this.get('modalGlobalMessage').title = i18n.t('components:modals.delete.title');
        this.get('modalGlobalMessage').icons.push('bmi-delete-modal-icon');
        this.get('modalGlobalMessage').message = i18n.t('modals.delete.title', {
          ns: namespace,
          count: deleteCount
        });
        this.get('modalGlobalMessage').additionalText = additionalMessage;
        this.get('modalGlobalMessage').buttons = [{
          type: 'cancel',
          label: i18n.t('common:actions.cancel'),
          className: 'bmb-rectangle bmb-rectangle-border-on-hover'
        }, {
          type: 'delete',
          label: i18n.t('common:actions.confirm'),
          className: 'bmb-rectangle bmb-confirm',
          callback: function callback() {
            var recordsToDestroy = [];
            if (typeof context.beforeDeletion === 'function') {
              context.beforeDeletion.apply(_this8);
            }

            // use copy() because we update "selected" content in the loop!
            (0, _emberCopy.copy)(selected).forEach(function (item /*, index, enumerable*/) {
              var record = _this8.getEntityFromId(item, mainModel);
              if (record) {
                var $row = (0, _jquery.default)("[data-row-id-".concat(item, "=true]"));
                $row.addClass('danger');
                var adapterOptions = {};
                if (typeof _this8.get('deleteApp') !== 'undefined') {
                  adapterOptions = {
                    adapterOptions: {
                      app: _this8.get('deleteApp').app,
                      type: _this8.get('deleteApp').type
                    }
                  };
                }
                if (typeof _this8.get('additionalParamsForAppNoCode') !== 'undefined') {
                  Ember.assign(adapterOptions, {
                    adapterOptions: _this8.get('additionalParamsForAppNoCode')
                  });
                }
                recordsToDestroy.push(
                // because we catch "reject" (2nd cb, ie. `function(error)`)
                // all items in `result` array will be in state 'fulfilled' when
                // we'll use `allSettled` (see after)
                // With this notation (`Promise` + `destroyRecord`, we can
                // reject the promise when we catch the rejected record.destroyRecord.
                // So we'll have a `result` array with items 'fulfilled' if deleted
                // or 'reject' if not when we'll use `allSettled`;p
                new Ember.RSVP.Promise(function (resolve, reject) {
                  record.deleteRecord();
                  record.save(adapterOptions).then(function () {
                    // executed after each "destroy"
                    selected.removeObject(item);
                    resolve(item);
                  }, function (error) {
                    record.rollbackAttributes();
                    // to get the record in the allSettled `result[].reason' prop
                    reject({
                      error: error,
                      record: record
                    });
                  });
                }));
              }
            });
            var notifyTitle = i18n.t('components:modals.delete.xItems.notify.title', {
              count: deleteCount
            });
            var notifyMsg = '';
            var recordsNotDestroyed = [];
            return Ember.RSVP.allSettled(recordsToDestroy, "Destroy ".concat(deleteCount, " ").concat(mainModel, " record(s)")).then(function (result) {
              recordsNotDestroyed = result.reduce(function (recordsNotDestroyed, promise) {
                if (promise.state === 'rejected') {
                  // promise.reason.record = record (Ember.Object) - see before when we push in `recordsToDestroy`
                  recordsNotDestroyed.push(promise.reason.record);
                }
                return recordsNotDestroyed;
              }, []);

              // executed when all "recordsToDestroy" promises have been settled
              // result = array of the states of these promises
              if (recordsNotDestroyed.length) {
                // so some promises have been rejected :'(
                var nbDeleted = deleteCount - recordsNotDestroyed.length;
                notifyMsg = i18n.t('components:modals.delete.xItems.notify.error.common') + ' ' + i18n.t('components:modals.delete.xItems.notify.error.details', {
                  count: nbDeleted
                });
                _this8.set('notDeleted', Ember.A(recordsNotDestroyed));
                _this8.get('notify').error(notifyMsg, notifyTitle);
              } else {
                // all destroyed :D
                notifyMsg = i18n.t('components:modals.delete.xItems.notify.success', {
                  count: deleteCount
                });
                _this8.get('notify').success(notifyMsg, notifyTitle);
              }
            }).finally(function () {
              // cleanup selection
              _this8.send('uncheckAllCurrentSearch');
              if (typeof context.afterDeletion === 'function') {
                context.afterDeletion(recordsToDestroy.length, recordsNotDestroyed.length);
              }

              // refresh route to update model, etc
              if (_this8.get('refreshAfterDeletion')) {
                var page = _this8.get('page');
                var total = _this8.get('totalPages');
                if (recordsNotDestroyed.length || page !== total || page === 1) {
                  Ember.getOwner(_this8).lookup("route:".concat(_this8.get('router.currentRouteName'))).refresh();
                } else {
                  // updateFilter with previous page if current page is last page
                  _this8.send('updateFilter', 'page', total - 1);
                }
              }
            });
          }
        }];
        this.get('modalGlobalMessage').open();
      },
      mailto: function mailto() {
        var email = this.listMailSelected();
        if (Ember.isEmpty(email)) {
          return false;
        }
        this.get('openProtocol').mailTo(email);
      },
      // == /Batch actions ===================================================
      emptyNotDeleted: function emptyNotDeleted() {
        var notDeleted = this.get('notDeleted');
        notDeleted.forEach(function (item) {
          var $row = (0, _jquery.default)("[data-row-id-".concat(item.get('id'), "]"));
          $row.removeClass('danger');
        });
        notDeleted.clear();
      },
      emptyNotModified: function emptyNotModified() {
        var notModified = this.get('notModified');
        var notModifiedErrors = this.get('notModifiedErrors');
        notModified.forEach(function (item) {
          var $row = (0, _jquery.default)("[data-row-id-".concat(item.get('id'), "]"));
          $row.removeClass('danger');
        });
        notModified.clear();
        notModifiedErrors.clear();
      },
      emptyNotDuplicated: function emptyNotDuplicated() {
        var notDuplicated = this.get('notDuplicated');
        notDuplicated.forEach(function (item) {
          var $row = (0, _jquery.default)("[data-row-id-".concat(item.get('id'), "]"));
          $row.removeClass('danger');
        });
        notDuplicated.clear();
      },
      orderSearch: function orderSearch(newSort) {
        if (!newSort) return;
        var oldSort = this.get('sort');
        var oldOrder = this.get('order');
        var newOrder = 'asc';
        if (newSort === oldSort) {
          switch (oldOrder) {
            case 'asc':
              newOrder = 'desc';
              break;
            case 'desc':
              newSort = '';
              newOrder = '';
              break;
            default:
              newOrder = 'asc';
              break;
          }
        }
        this.set('sort', newSort);
        this.set('order', newOrder);
        this.set('savedSearchId', undefined);
      },
      /**
       *
       * @param field
       * @param value
       */
      updateFilter: function updateFilter(field, value) {
        var debounce_field = field.replace('debounce_', '');
        if (!['saveSearch', 'loadSearch', 'savedSearchId'].includes(field)) {
          (false && !(this.get('queryParams').indexOf(field) !== -1 || this.get('queryParams').indexOf(debounce_field) !== -1 && Object.keys(this.get('debounceQueryParams')).indexOf(debounce_field) !== -1) && Ember.assert("\"".concat(field, "\" field must exists in controller's queryParams CP"), this.get('queryParams').indexOf(field) !== -1 || this.get('queryParams').indexOf(debounce_field) !== -1 && Object.keys(this.get('debounceQueryParams')).indexOf(debounce_field) !== -1));
        }
        if (Ember.isArray(value)) {
          this.set(field, value.map(function (item) {
            if (item.value) {
              return item.value; // ancien select
            } else {
              return item; //power select
            }
          }));
        } else {
          this.set(field, value);
        }
        this.toggleProperty('refreshActiveQueryParams');
        if (field !== 'page') {
          this.set('page', 1);
        } else {
          // changement de page, on remet le selected à 0
          if (typeof this.get('actions.uncheckAllCurrentSearch') === 'function') {
            this.send('uncheckAllCurrentSearch');
          }
        }
      },
      updatePerimeterFilter: function updatePerimeterFilter(values) {
        _logger.default.debug.apply(_logger.default, ['Mixin queryparams-management-controllers - updatePerimeterFilter action'].concat(Array.prototype.slice.call(arguments)));
        function groupBy(valuesByGroup, value) {
          var _value$split = value.split(_component.perimeterValueSeparator),
            _value$split2 = _slicedToArray(_value$split, 2),
            group = _value$split2[0],
            id = _value$split2[1];
          if (typeof id === 'undefined') {
            id = group;
            group = null;
          }
          if (group) {
            var grp = "perimeter".concat(Ember.String.capitalize(group));
            if (!valuesByGroup[grp]) {
              valuesByGroup[grp] = [];
            }

            /*delete value.group;
            valuesByGroup[grp].push(value);
            */
            valuesByGroup[grp].push(id);
          } else {
            /*
            if (!valuesByGroup['__NO_GROUP__']) {
            	valuesByGroup['__NO_GROUP__'] = [];
            }
            	valuesByGroup['__NO_GROUP__'].push(value);
            */
            _logger.default.warn('Perimeter value without group:', value);
          }
          return valuesByGroup;
        }
        if (!Ember.isArray(values)) {
          values = [values];
        }
        var valuesByGroup = values.reduce(groupBy, {
          perimeterAgencies: [],
          perimeterBusinessUnits: [],
          perimeterManagers: [],
          perimeterPoles: []
        });
        _logger.default.debug('Mixin queryparams-management-controllers - updatePerimeterFilter action - valuesByGroup = ', valuesByGroup);
        this.setProperties(valuesByGroup);
        this.set('page', 1);
        this.toggleProperty('refreshActiveQueryParams');
      },
      //TODO add this action dynamically (in init()) if query params 'perimeter*' exist.
      updatePeriodFilter: function updatePeriodFilter(value) {
        this.setProperties(value);
        this.set('page', 1);
        this.toggleProperty('refreshActiveQueryParams');
      },
      resetFilters: function resetFilters(module) {
        var router = this.get('router');
        var searchID = Ember.get(router, 'currentRoute.queryParams.saveSearch');
        var viewMode = Ember.get(router, 'currentRoute.queryParams.viewMode');
        var returnMoreData = Ember.get(router, 'currentRoute.queryParams.returnMoreData');
        var columns = Ember.get(router, 'currentRoute.queryParams.columns');
        var params = {};
        if (typeof searchID !== 'undefined') {
          params.saveSearch = searchID;
        }
        if (typeof viewMode !== 'undefined') {
          params.viewMode = viewMode;
          if (viewMode === _search.VIEW_MODE_KANBAN && typeof returnMoreData !== 'undefined') {
            params.returnMoreData = returnMoreData;
          }
        }
        if (typeof columns !== 'undefined') {
          params.columns = columns;
        }
        var routerInstance = Ember.getOwner(this).lookup("route:".concat(router.get('currentRouteName')));
        if (routerInstance.defaultSearchValues && typeof routerInstance.defaultSearchValues === 'function') {
          routerInstance.defaultSearchValues().then(function (defaultSearchValues) {
            var tmpParams = Object.assign({}, params, defaultSearchValues);
            var queryParams = routerInstance.cleanControllerQueryParams(tmpParams, router.get('currentRouteName'));
            router.transitionTo("private.".concat(module), {
              queryParams: queryParams
            });
          });
        } else {
          var queryParams = routerInstance.cleanControllerQueryParams(params, router.get('currentRouteName'));
          router.transitionTo("private.".concat(module), {
            queryParams: queryParams
          });
        }
      },
      onTodolistClick: function onTodolistClick() {
        this.get('tasksService').manageChipClick(this.get('router'), this.todolistRoute);
      },
      /**
       * Méthode déclenchée lorsque du hook de fermeture d'une modale déclenchée par une tâche additionnelle dans la todoliste
       * @param {Object} params
       * @private
       */
      onCloseAdditionalTask: function onCloseAdditionalTask() {} /* params */,
      // == Batch actions ====================================================
      /**
       * Action - Batch
       *
       * @event batch
       * @param {Object} action
       * @param {string} action.field  mainModel's field to update, like an attribute or a dotted path to an relationship's attribute
       * @param {*}      action.value
       * @param {string} action.type
       * @param {string} action.date
       * @param {boolean} action.updateAllContacts
       */
      batch: function batch(action) {
        var _this9 = this;
        this.set('showConfirmBatch', false);
        this.send('emptyNotModified');
        _logger.default.debug('datatable batch');
        if (typeof action === 'undefined') {
          _logger.default.error('Missing "action" argument when call batch() action!');
          return;
        }
        if (Ember.typeOf(action.field) !== 'string' || Ember.isEmpty(action.field)) {
          _logger.default.error('Bad "field" property in "action" argument when call batch() action!');
          return;
        }
        switch (action.field) {
          case 'addFlag':
            this.send('updateFlag', action.value, 'add');
            break;
          case 'removeFlag':
            this.send('updateFlag', action.value, 'remove');
            break;
          case 'paid':
            {
              var selected = this.get('selected');
              var batchCount = selected.length;
              var mainModel = this.get('mainModel');
              var i18n = this.get('i18n');
              var notifyTitle = i18n.t('components:modals.pay.xItems.notify.title', {
                count: batchCount
              });
              var reportsToPay = [];
              var options = {
                adapterOptions: {
                  endpoint: 'pay'
                }
              };

              // use copy() because we update "selected" content in the loop!
              this.onBeforeBatch(selected).then(function (selected) {
                (0, _emberCopy.copy)(selected).forEach(function (item /*, index, enumerable*/) {
                  var recordStore = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
                    resolve(_this9.getEntityFromId(item, mainModel));
                  });
                  reportsToPay.push(new Ember.RSVP.Promise(function (resolve, reject) {
                    recordStore.then(function (record) {
                      var $row = (0, _jquery.default)("[data-row-id-".concat(item, "=true]"));
                      $row.addClass('bm-danger');
                      record.save(options).then(function (savedModel) {
                        // executed after each "update"
                        selected.removeObject(item);
                        $row.removeClass('bm-danger');
                        return _this9.onBatchSuccess(savedModel).then(function () {
                          resolve(item);
                        });
                      }, function (error) {
                        record.rollbackAttributes();
                        return _this9.onBatchError(error).then(function () {
                          reject({
                            error: error,
                            record: record
                          });
                        });
                      });
                    });
                  }));
                });
                Ember.RSVP.allSettled(reportsToPay, "Pay ".concat(selected.length, " ").concat(mainModel, " record(s)")).then(function (result) {
                  var reportsNotPayed = result.reduce(function (reportsNotPayed, promise) {
                    if (promise.state === 'rejected') {
                      // promise.reason.record = record (Ember.Object) - see before when we push in `recordsToDestroy`
                      reportsNotPayed.push(promise.reason.record);
                    }
                    return reportsNotPayed;
                  }, []);

                  // executed when all "recordsToDestroy" promises have been settled
                  // result = array of the states of these promises
                  if (reportsNotPayed.length) {
                    // so some promises have been rejected :'(
                    var nbPayed = batchCount - reportsNotPayed.length;
                    var notifyMsg = i18n.t('components:modals.pay.xItems.notify.error.common') + ' ' + i18n.t('components:modals.pay.xItems.notify.error.details', {
                      count: nbPayed
                    });
                    _this9.set('notModified', Ember.A(reportsNotPayed));
                    _this9.get('notify').error(notifyMsg, notifyTitle);
                  } else {
                    // all destroyed :D
                    var _notifyMsg = i18n.t('components:modals.pay.xItems.notify.success', {
                      count: batchCount
                    });
                    _this9.get('notify').success(_notifyMsg, notifyTitle);
                  }
                }).finally(function () {
                  // cleanup selection
                  _this9.send('uncheckAllCurrentSearch');
                });
              });
              break;
            }
          default:
            {
              // Check field value: is it a valid attr/relationship ?
              var mainModelClass = this.get('mainModelClass');
              //1 - list all fields (attr + relationships)
              // CAUTION `Ember.get` and `forEach` (no map() method) must be used here!
              var fields = Ember.get(mainModelClass, 'fields');
              var authorizedFields = [];
              fields.forEach(function (type, name) {
                authorizedFields.push(name);
              });
              // 2 - check validity
              if (authorizedFields.indexOf(action.field) === -1) {
                _logger.default.error("Bad \"field\" property in \"action\" argument: ".concat(action.field));
                return;
              }
              if (typeof action.value === 'undefined') {
                _logger.default.error('Missing "value" property in "action" argument when call batch() action!');
                return;
              }

              // All is ok: we can begin :)
              var _selected = this.get('selected');
              if (!_selected) {
                return;
              }
              var _mainModel = this.get('mainModel');
              var store = this.store;
              var _i18n = this.get('i18n');
              var _batchCount = _selected.length;

              // determine value (attribute or relationship ?) to set
              var field = action.field.replace(/^(\+|-)/, '');
              var fieldType = fields.get(field);
              var value;
              var sendAttrs = [];
              switch (fieldType) {
                case 'attribute':
                  value = action.value;
                  sendAttrs.push(action.field);
                  break;
                case 'belongsTo':
                case 'hasMany':
                  {
                    // it's a relationship: we need its model type !
                    var modelType = Ember.get(mainModelClass, 'relationshipsByName').get(field).type;
                    if (action.value !== '') {
                      value = this.getEntityFromId(action.value, modelType);
                    } else {
                      value = action.value;
                    }
                    break;
                  }
                default:
                  _logger.default.error("Bad field's type: ".concat(fieldType, "!?"));
                  return;
              }
              if (typeof value === 'undefined') {
                _logger.default.error("Bad value: undefined!?");
                return;
              }
              var recordsToUpdate = [];
              var allRecords = [];

              // use copy() because we update "selected" content in the loop!
              (0, _emberCopy.copy)(_selected).forEach(function (item /*, index, enumerable*/) {
                var recordStore = new Ember.RSVP.Promise(function (resolve /*, reject*/) {
                  resolve(_this9.getEntityFromId(item, _mainModel));
                });
                if (action.field === 'influencers' || _this9.retrieveModelBeforeBatch(_mainModel)) {
                  //S'il s'agit des influencers alors on lance une requete serveur
                  recordStore = store.queryRecord(_mainModel, {
                    id: item,
                    endpoint: 'information'
                  });
                }
                allRecords.push(recordStore);
              });
              Ember.RSVP.allSettled(allRecords, "Get all records for batch ".concat(action.field, " to ").concat(action.value, " on ").concat(_batchCount, " ").concat(_mainModel, " record(s)")).then(function (result) {
                var availableModule = ['candidate', 'resource', 'project', 'opportunity', 'positioning', 'appadvancedcandidatescandidate'];
                // ici l'idée est de pouvoir effectuer un traitement sur la sélection avant d'exécuter le batch
                // par exemple, sur les factures, lorsque nous allons les modifier, nous allons le faire par date croissante
                // afin d'éviter d'avoir (si clôture de période configurée) la dernière facture en date qui bloque les autres
                _this9.onBeforeBatch(_selected).then(function (selected) {
                  (0, _emberCopy.copy)(selected).forEach(function (item /*, index, enumerable*/) {
                    var recordStore = result.find(function (promise) {
                      // Certains IDs vont être composé d'un 'ID' + '_' + 'SUFFIXE libre' cf bm-table-list/check avec textToAdd
                      var id = item.split('_')[0];
                      return promise.value.get('id') === item || promise.value.get('id') === id;
                    });
                    var record = recordStore.value;
                    recordsToUpdate.push(
                    // 2 - persist update
                    new Ember.RSVP.Promise(function (resolve, reject) {
                      var $row = (0, _jquery.default)("[data-row-id-".concat(item, "=true]"));
                      $row.addClass('bm-danger');

                      // 1 - set field to update and mark object as updating for Solr
                      if (typeof action.type === 'undefined') {
                        record.set(field, value);
                        if (field === 'state' && availableModule.includes(record.get('modelName'))) {
                          record.set('stateReason', action.valueStateReason);
                          sendAttrs.push('stateReason');
                        }
                      }
                      if (action.type === 'add') {
                        record.get(field).pushObject(value);
                      }
                      if (action.type === 'remove') {
                        record.get(field).removeObject(value);
                      }
                      if (action.date && field === 'state') {
                        if (record.get('modelName') === 'invoice' && value === _invoice.STATE_PAID) {
                          record.set('performedPaymentDate', action.date);
                          // Création d'un paiement dans le cas du passage de la facture a l'état payé depuis la barre de faire
                          if (record.amountPayableIncludingTax > 0 || record.get('isCreditNote') && record.amountPayableIncludingTax < 0) {
                            record.get('invoicePayments').createFragment({
                              createdAt: action.date,
                              amountIncludingTax: Math.abs(record.amountPayableIncludingTax),
                              comment: ''
                            });
                            sendAttrs.push('invoicePayments');
                          }
                          sendAttrs.push('performedPaymentDate');
                        } else if (record.get('modelName') === 'payment' && value === _payment.STATE_PAID) {
                          record.set('performedDate', action.date);
                          sendAttrs.push('performedDate');
                        }
                      }
                      var adapterOptions = {};
                      switch (record.get('modelName')) {
                        case 'appintranetaccountsresource':
                          if (action.field === 'subscription' && action.value === 'active') {
                            if (typeof action.activityExpensesStartDate === 'undefined') {
                              action.activityExpensesStartDate = 'hiringDate';
                            }
                            record.set('activityExpensesStartDate', action.activityExpensesStartDate);
                            sendAttrs.push('activityExpensesStartDate');
                          }
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'intranet-accounts',
                            type: 'resources'
                          };
                          break;
                        case 'time':
                          //On gère le cas de la modification en masse sur exceptionalActivity
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'exceptional-activity',
                            type: 'times'
                          };
                          break;
                        case 'appdataclosinginvoice':
                          //On gère le cas de la modification en masse sur exceptionalActivity
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'data-closing',
                            type: 'invoices'
                          };
                          break;
                        case 'appdataclosingtimesreport':
                          //On gère le cas de la modification en masse sur exceptionalActivity
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'data-closing',
                            type: 'times-reports'
                          };
                          break;
                        case 'appdataclosingexpensesreport':
                          //On gère le cas de la modification en masse sur exceptionalActivity
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'data-closing',
                            type: 'expenses-reports'
                          };
                          break;
                        case 'appadvancedcandidatescandidate':
                          adapterOptions = {
                            sendAttrs: sendAttrs,
                            app: 'advanced-candidates',
                            type: 'candidates'
                          };
                          break;
                        default:
                          adapterOptions = ['flag', 'payment', 'action', 'expensesreport', 'positioning', 'deliveriesGroupments', 'savedsearch'].includes(_mainModel) ? {
                            sendAttrs: sendAttrs
                          } : {
                            endpoint: 'information',
                            sendAttrs: sendAttrs
                          };
                          break;
                      }
                      if (action.updateAllContacts) {
                        Ember.assign(adapterOptions, {
                          queryParams: {
                            cascade: field
                          }
                        });
                      }
                      record.save({
                        adapterOptions: adapterOptions
                      }).then(function (savedModel) {
                        // executed after each "update"
                        selected.removeObject(item);
                        $row.removeClass('bm-danger');
                        return _this9.onBatchSuccess(savedModel).then(function () {
                          resolve(item);
                        });
                      }, function (error) {
                        record.rollbackAttributes();
                        return _this9.onBatchError(error).then(function () {
                          reject({
                            error: error,
                            record: record
                          });
                        });
                      });
                    }, function (error) {
                      Ember.RSVP.Promise.reject({
                        error: error,
                        record: null
                      });
                    }));
                  });
                  var notifyTitle = _i18n.t('components:modals.batch.xItems.notify.title', {
                    count: _batchCount
                  });
                  var notifyMsg = '';

                  // see http://www.emberjs.com.cn/api/classes/RSVP.html#method_allSettled
                  Ember.RSVP.allSettled(recordsToUpdate, "Update ".concat(action.field, " to ").concat(action.value, " on ").concat(_batchCount, " ").concat(_mainModel, " record(s)")).then(function (result) {
                    var recordsNotModified = result.reduce(function (recordsNotModified, promise) {
                      if (promise.state === 'rejected') {
                        // promise.reason.record = record (Ember.Object) - see before when we push in `recordsToDestroy`
                        if (promise.reason.record) {
                          recordsNotModified.push(promise.reason.record);
                          _this9.set('notModifiedErrors', promise.reason.error.errors);
                        }
                      }
                      return recordsNotModified;
                    }, []);

                    // executed when all "recordsToUpdate" promises have been settled
                    // result = array of the states of these promises
                    if (recordsNotModified.length) {
                      // so some promises have been rejected :'(
                      var nbUpdated = _batchCount - recordsNotModified.length;
                      notifyMsg = _i18n.t('components:modals.batch.xItems.notify.error.common') + ' ' + _i18n.t('components:modals.batch.xItems.notify.error.details', {
                        count: nbUpdated
                      });
                      _this9.set('notModified', Ember.A(recordsNotModified));
                      _this9.get('notify').error(notifyMsg, notifyTitle);
                    } else {
                      // all updated :D
                      notifyMsg = _i18n.t('components:modals.batch.xItems.notify.success', {
                        count: _batchCount
                      });
                      _this9.get('notify').success(notifyMsg, notifyTitle);
                    }
                  }).finally(function () {
                    // cleanup selection
                    _this9.send('uncheckAllCurrentSearch');
                    // refresh route to update model, etc
                    Ember.getOwner(_this9).lookup("route:".concat(_this9.get('router.currentRouteName'))).refresh();
                  });
                });
              });
              break;
            }
        }
      },
      /**
       * Action - Confirm batch
       *
       * @event confirm batch
       */
      confirmBatch: function confirmBatch(params) {
        params.date = (0, _moment.default)();
        params.selectedLength = this.get('selected.length');
        this.set('batchParams', params);
        this.set('showConfirmBatch', true);
      },
      closeBatchModal: function closeBatchModal() {
        this.set('showConfirmBatch', false);
      }
    }
  });
});