define("boondmanager/pods/components/octane/bm-field/tinymce/form-description/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0EYz2iH4",
    "block": "{\"symbols\":[\"@name\",\"@placeholder\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-field-tinymce bmc-field-tinymce-one-line bmc-field-tinymce-form-description\"],[17,3],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,0,[\"contentValue\"]]],null],[4,[38,1],[[32,0,[\"reloadTinyMCE\"]],[32,0,[\"currentUser\",\"theme\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"disabled\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"textarea\",[[16,0,[32,0,[\"inputClass\"]]]],[[\"@name\",\"@value\",\"@id\",\"@onchange\",\"@placeholder\"],[[32,1],[32,0,[\"contentValue\"]],[32,0,[\"getElementId\"]],[32,0,[\"onValueChanged\"]],[32,2]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"bmc-field-template-tinymce-disabled\"],[12],[2,\"\\n      \"],[8,\"octane/bm-tiny-mce-text\",[],[[\"@value\"],[[32,0,[\"contentValue\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"not\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/tinymce/form-description/template.hbs"
  });
});