define("boondmanager/pods/components/octane/bm-rights-filters/fields/manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "o9mb6qhl",
    "block": "{\"symbols\":[\"@filter\",\"@disabled\",\"@displayNbSelected\"],\"statements\":[[8,\"octane/bm-field/account\",[],[[\"@name\",\"@selected\",\"@initialSelect\",\"@onChange\",\"@multiple\",\"@userSubscriptions\",\"@renderInPlace\",\"@disableCheckAll\",\"@disabled\",\"@displayNbSelected\",\"@addMissingSelected\",\"@noValuePlaceholder\",\"@nbDisplayedItems\"],[\"account\",[32,1,[\"modalValue\"]],[32,1,[\"modalValue\"]],[32,0,[\"update\"]],true,null,false,true,[32,2],[30,[36,1],[[30,[36,0],[[32,3],false],null]],null],false,[30,[36,2],[\"common:none\"],[[\"context\"],[\"account\"]]],3]],null]],\"hasEval\":false,\"upvars\":[\"eq\",\"not\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-rights-filters/fields/manager/template.hbs"
  });
});