define("boondmanager/pods/components/octane/bm-field/customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VSv04QIt",
    "block": "{\"symbols\":[\"&attrs\",\"@selected\",\"@name\",\"@multiple\",\"@renderInPlace\",\"@disabled\",\"@required\",\"@nbDisplayedItems\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"bmc-field-customer \",[32,0,[\"classNames\"]]]]],[17,1],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onUpdate\"]],[32,0,[\"selected\"]]],null],[4,[38,1],[[32,0,[\"onUpdateSelected\"]],[32,2]],null],[12],[2,\"\\n\\t\"],[8,\"octane/bm-field/power-search\",[[16,3,[32,3]]],[[\"@customSearch\",\"@selected\",\"@multiple\",\"@renderInPlace\",\"@placeholder\",\"@loadingMessage\",\"@selectAll\",\"@onChange\",\"@disabled\",\"@required\",\"@nbDisplayedItems\",\"@displayNbSelected\",\"@valueKeyName\"],[[32,0,[\"searchItems\"]],[32,0,[\"selected\"]],[32,4],[32,5],[30,[36,2],[\"components:bmFieldSearchItems.searchMessage\"],[[\"context\"],[\"customer\"]]],[32,0,[\"loadingMessage\"]],[32,0,[\"selectAll\"]],[32,0,[\"onChange\"]],[32,6],[32,7],[32,8],[32,0,[\"displayNbSelected\"]],\"name\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"t\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-field/customer/template.hbs"
  });
});