define("boondmanager/models/bankingtransaction", ["exports", "boondmanager/models/base", "ember-data", "ember-data-model-fragments/attributes"], function (_exports, _base, _emberData, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BANKING_TRANSACTION_STATE_VALIDATED = _exports.BANKING_TRANSACTION_STATE_RECONCILED = _exports.BANKING_TRANSACTION_STATE_PARTIALLY_RECONCILED = _exports.BANKING_TRANSACTION_STATE_NOT_RECONCILED = void 0;
  var BANKING_TRANSACTION_STATE_NOT_RECONCILED = _exports.BANKING_TRANSACTION_STATE_NOT_RECONCILED = 0;
  var BANKING_TRANSACTION_STATE_PARTIALLY_RECONCILED = _exports.BANKING_TRANSACTION_STATE_PARTIALLY_RECONCILED = 1;
  var BANKING_TRANSACTION_STATE_VALIDATED = _exports.BANKING_TRANSACTION_STATE_VALIDATED = 2;
  var BANKING_TRANSACTION_STATE_RECONCILED = _exports.BANKING_TRANSACTION_STATE_RECONCILED = 3;
  var BankingTransaction = _base.default.extend({
    state: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    amount: _emberData.default.attr('number'),
    totalAmountToReconcile: _emberData.default.attr('number'),
    totalAmountReconciled: _emberData.default.attr('number'),
    currency: _emberData.default.attr('number'),
    numberOfInvoices: _emberData.default.attr('number'),
    exchangeRate: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    paymentMethod: _emberData.default.attr('string'),
    canWriteTransaction: _emberData.default.attr('boolean'),
    canReadTransaction: _emberData.default.attr('boolean'),
    canReconcile: _emberData.default.attr('boolean'),
    invoicePayments: (0, _attributes.fragmentArray)('bankingtransactionpayment'),
    account: _emberData.default.belongsTo('bankingaccount'),
    /**
     * transaction state color class
     */
    stateClass: Ember.computed('state', 'i18n._locale', function () {
      var _this = this;
      var states = this.i18n.t('models:bankingtransaction.attributes.state.values', {
        returnObjects: true
      });
      var state = states.find(function (s) {
        return s.id === parseInt(_this.get('state'));
      });
      return "bm-state-color-".concat(state ? state.color : '');
    }),
    /**
     * transaction entity name
     */
    entityName: Ember.computed('title', function () {
      return this.get('title');
    })
  });

  /**
   * Prefix handling
   */
  BankingTransaction.reopenClass({
    prefix: 'BNKTRS'
  });
  BankingTransaction.reopen({
    prefix: Ember.computed(function () {
      return BankingTransaction.prefix;
    }).readOnly()
  });
  var _default = _exports.default = BankingTransaction;
});